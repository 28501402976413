import LeftNavTemplate from "../../assets/images/left-nav-template.png";
import CentredTemplate from "../../assets/images/centred-template.png";
import BMODSUpdate from "../../assets/images/BMO-DS-update.png";
import BMOSecureRetailTypeStack from "../../assets/images/BMO-secure-retail-type-stack.png";
import CDBSpacingRule from "../../assets/images/CDB-spacing-rule.png";
import BMOColorScheme from "../../assets/images/BMO-color-scheme.png";
import BMODocumentation from "../../assets/images/BMO-documentation.png";
import BMOListComponent from "../../assets/images/BMO-list-component.png";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";
import {
  PartTitle,
  ImageComp,
  ImageCaption,
} from "../BMOPage/BMODetails.styled";
import { DetailsContentProps } from "../BMOPage/DetailsContent";

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  return (
    <>
      <div id={navItems[0].hash}>
        <PartTitle>Grid layout redesign</PartTitle>
        <ImageComp>
          <ImageContainer src={LeftNavTemplate} alt="left nav template" />
        </ImageComp>
        <ImageComp>
          <ImageContainer src={CentredTemplate} alt="centred template" />
          <ImageCaption>
            Worked with a11y folks and dev to deliver the redesigned layout
          </ImageCaption>
        </ImageComp>

        <PartTitle>Mobile fonts & spacing redesign</PartTitle>
        <ImageComp>
          <ImageContainer src={BMODSUpdate} alt="BMO DS update" />
        </ImageComp>
        <ImageComp>
          <ImageContainer
            src={BMOSecureRetailTypeStack}
            alt="BMO secure retail type stack"
          />
        </ImageComp>
        <ImageComp>
          <ImageContainer src={CDBSpacingRule} alt="CDB spacing rule" />
          <ImageCaption>
            worked with dev and design to resize type and padding for mobile
            screens
          </ImageCaption>
        </ImageComp>

        <PartTitle>Adding colour to the library</PartTitle>
        <ImageComp>
          <ImageContainer src={BMOColorScheme} alt="BMO color scheme" />
          <ImageCaption>
            worked with marketing team to add complimentary colours to the
            colour scheme
          </ImageCaption>
        </ImageComp>

        <PartTitle>Sample of documentation work</PartTitle>
        <ImageComp>
          <ImageContainer src={BMODocumentation} alt="BMO documentation" />
          <ImageCaption>Delivering visual spec and documentation</ImageCaption>
        </ImageComp>

        <PartTitle>Work layers and Component Set-up</PartTitle>
        <ImageComp>
          <ImageContainer src={BMOListComponent} alt="BMO list component" />
          <ImageCaption>
            how I set up the component so my designer-users can use it
          </ImageCaption>
        </ImageComp>
      </div>
    </>
  );
};
