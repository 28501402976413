import { useEffect, useMemo } from "react";
import { DebouncedFunc, throttle } from "lodash";

const useThrottledOnScroll = (callback: (() => void) | null, delay: number) => {
  const throttledCallback: DebouncedFunc<() => void> = useMemo(
    () =>
      (callback ? throttle(callback, delay) : noop) as DebouncedFunc<
        () => void
      >,
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined;
    }

    window.addEventListener("scroll", throttledCallback);
    return () => {
      window.removeEventListener("scroll", throttledCallback);

      throttledCallback.cancel();
    };
  }, [throttledCallback]);
};

const noop = () => {};

export default useThrottledOnScroll;
