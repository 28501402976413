import DesignSystem1 from "../../assets/images/snc-design-system-1.png";
import DesignSystem2 from "../../assets/images/snc-design-system-2.png";
import DesignSystem3 from "../../assets/images/snc-design-system-3.png";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";
import {
  PartTitle,
  ContentText,
  HighlightBlock,
  HighlightBlockText,
  ImageComp,
  ImageCaption,
} from "../BMOPage/BMODetails.styled";
import { DetailsContentProps } from "../BMOPage/DetailsContent";

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  return (
    <>
      <div id={navItems[0].hash}>
        <PartTitle>Overview</PartTitle>
        <ContentText>
          What is a Digital Twin?
          {"\n"}
          {"\n"}A Digital Twin is a digital representation of a physical object
          - this object could be a human, a building, or, in the case of SNC
          Lavalin, an engineering site. This Digital Twin helps engineers in
          simulating what are happening on site, showing real-time data such as
          electricity, lighting, traffics and security, thus enables them to
          make better decisions.
          {"\n"}
          {"\n"}
          During my days at SNC Lavalin, I contributed to 3 large-scale Digital
          Twin projects by delivering user flows and user interfaces. My team
          and I had to design our own design system from the ground up, and work
          closely with 3D Designers and developers to deliver realistic and
          user-friendly designs.
          {"\n"}
          {"\n"}
          Because of the Non-disclosure Agreement, I couldn’t put my
          deliverables right here. I would like to share 3 screenshots of the
          design systems we created, if you’re interested in having a chat,
          please connect with me!
        </ContentText>
        <HighlightBlock>
          <HighlightBlockText noMarginBottom>
            All components were built before the introduction of Variants
            feature.
          </HighlightBlockText>
        </HighlightBlock>
        <ImageComp>
          <ImageContainer src={DesignSystem1} alt="Design System 1" />
          <ImageCaption>
            Design system 1, built for a digital twin that can navigate through
            the site in first person view
          </ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer src={DesignSystem2} alt="Design System 2" />
          <ImageCaption>
            Design system 2, built for a digital twin that can track light-rail
            traffic
          </ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer src={DesignSystem3} alt="Design System 3" />
          <ImageCaption>
            Design system 3, built for a digital twin that can manage building
            and site
          </ImageCaption>
        </ImageComp>

        <ContentText sx={{ marginTop: "80px" }}>
          This was my adventure in the Digital Twin world. Even though, as a 'UX
          start-up' in a traditional engineering company, we couldn't get
          everything right from the beginning, I still appreciate this
          opportunity as it is my first job after graduating. 😊
        </ContentText>
      </div>
    </>
  );
};
