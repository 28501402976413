import { useMediaQuery, useTheme } from "@mui/material";

export enum Breakpoints {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
  maximum = "maximum",
}

export const useCurrentBreakpoint = (): Breakpoints => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md")) && !isMobile;
  const isDesktop =
    useMediaQuery(theme.breakpoints.down("xl")) && !isTablet && !isMobile;

  if (isMobile) return Breakpoints.mobile;

  if (isTablet) return Breakpoints.tablet;

  if (isDesktop) return Breakpoints.desktop;

  return Breakpoints.maximum;
};
