import { Box, Stack, styled } from "@mui/material";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";

import InformationArchitecture from "../../assets/images/icity-information-architecture.png";
import LogoDesign from "../../assets/images/icity-logo-design.png";
import Wireframe from "../../assets/images/icity-wireframe.png";

import {
  ContentText,
  ImageComp,
  ImageCaption,
  NextLink,
} from "../BMOPage/BMODetails.styled";
import { DetailsContentProps } from "../BMOPage/DetailsContent";

const AppLinkText = styled(ContentText)<{ $isLink?: boolean }>(
  ({ $isLink }) => ({
    margin: "16px 0",
    fontWeight: 700,
    textDecoration: $isLink ? "underline" : "none",
    cursor: $isLink ? "pointer" : "default",
  })
);

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  return (
    <>
      <Stack direction="row" margin="80px 0">
        <Box>
          <AppLinkText $isLink>
            <NextLink href="http://icity.utoronto.ca/" target="_blank">
              Click here to view the iCity site
            </NextLink>
          </AppLinkText>
        </Box>
      </Stack>
      <div id={navItems[0].hash}>
        <ImageComp>
          <ImageContainer
            src={InformationArchitecture}
            alt="Information architecture"
          />
          <ImageCaption>INFORMATION ARCHITECTURE</ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer src={LogoDesign} alt="Logo design" />
          <ImageCaption>LOGO DESIGN</ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer src={Wireframe} alt="Wireframe" />
          <ImageCaption>WIREFRAME</ImageCaption>
        </ImageComp>
      </div>
    </>
  );
};
