import { Box, Tab, Tabs, styled } from "@mui/material";
import { colors } from "../../../common";

export const NavWrapper = styled(Box)({
  position: "fixed",
  top: "50%",
  overflow: "hidden",
  transform: "translateY(-50%)",
  width: "12%",
});

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number | false;
  onChange?: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    orientation="vertical"
    TabIndicatorProps={{
      children: (
        <span
          hidden={props.value === false}
          className="MuiTabs-indicatorSpan"
        />
      ),
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

interface StyledTabProps {
  label: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ isActive, theme }) => ({
  textTransform: "none",
  "&.Mui-selected": {
    color: colors.white,
  },
  color: colors.white,
  fontFamily: "'Raleway',sans-serif",
  textAlign: "left",
  alignItems: "self-start",
  justifyContent: "flex-start",
  fontSize: "16px",
  fontWeight: isActive ? 700 : 400,
  padding: theme.spacing(1, 1),
  minHeight: "30px",
  "&:hover": {
    fontWeight: 700,
  },
}));
