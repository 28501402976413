import { Box, styled } from "@mui/material";
import { useMemo } from "react";
import { StyledLink, StyledTypography } from "../styles/styled-components";
import { Divider } from "../Divider/Divider";
import { useCurrentBreakpoint } from "../../services";
import { PAGE_WRAPPER_RESPONSIVE_DATA } from "../Header/Header";
import { colors } from "../../common";
import Resume from "../../assets/pdf/AprilYuResume_2024.pdf";

const FooterText = styled(StyledTypography)({
  fontSize: "18px",
  fontWeight: 500,
});

const FooterLink = styled(StyledLink)({
  color: "inherit",
});

export const Footer = () => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => PAGE_WRAPPER_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );

  return (
    <Box sx={{ margin: "0 auto", backgroundColor: colors.black }}>
      <Divider marginX={responsiveUI.paddingX} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          padding: "16px 24px 120px",
        }}
      >
        <FooterText sx={{ marginRight: "24px" }}>© April Yu</FooterText>
        <FooterLink
          href="https://www.linkedin.com/in/april-yu-design/"
          target="_blank"
          rel="noreferrer"
        >
          {/* <a
            style={{ textDecoration: "none" }}
      
          > */}
          LinkdedIn
          {/* </a> */}
        </FooterLink>
        <FooterText>&nbsp;/&nbsp;</FooterText>
        <FooterLink href="mailto:aprilyudesign@gmail.com">Email</FooterLink>
        <FooterText>&nbsp;/&nbsp;</FooterText>
        <FooterLink href={Resume} target="_blank">
          Resume
        </FooterLink>
      </Box>
    </Box>
  );
};
