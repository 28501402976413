import { AppWrapper } from "./App.styled";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { theme } from "./common";
import "react-medium-image-zoom/dist/styles.css";
import { router } from "./routes/router";

function App() {
  return (
    <AppWrapper>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </AppWrapper>
  );
}

export default App;
