import { Box, Grid } from "@mui/material";
import {
  SubLabel,
  TextWrapper,
  WorkHeader,
  NextLink,
} from "./BMODetails.styled";
import React, { useMemo } from "react";

import { DetailsContent } from "./DetailsContent";
import { useNavigate } from "react-router-dom";
import { useCurrentBreakpoint } from "../../services";
import {
  NavItem,
  WORK_PAGE_RESPONSIVE_DATA,
} from "../../components/WorkPageTemplate/WorkPage";
import { WorkPageType } from "../../components/WorkPageTemplate/navParts";

export interface WorkDetailsProps {
  navItems: NavItem[];
}

export const BMODetails: React.FC<WorkDetailsProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        letterSpacing: "0.27px",
      }}
    >
      <div id="text-header">
        <WorkHeader sx={{ paddingY: responsiveUI.containerPaddingY }}>
          Filter, for daily financial convenience
        </WorkHeader>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={3.5}>
            <SubLabel>ROLE</SubLabel>
            <TextWrapper>Product Designer</TextWrapper>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <SubLabel>DURATION</SubLabel>
            <TextWrapper>~3 months</TextWrapper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <SubLabel>TEAM</SubLabel>
            <TextWrapper>
              1 designer, 1 product owner, 1 tech lead, Design system team,
              Accessibility team, 3 front-end developers, Back-end engineering
              team.
            </TextWrapper>
          </Grid>
        </Grid>
      </div>
      <DetailsContent navItems={navItems} />
      <Box
        sx={{
          textAlign: "right",
          margin: `${responsiveUI.containerPaddingY} 0`,
        }}
      >
        <Box>
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.bmoDs}` });
            }}
          >
            Previous project: BMO Secure DS work
          </NextLink>
        </Box>

        <Box
          sx={{
            marginTop: `${parseInt(responsiveUI.containerPaddingY) / 3}px`,
          }}
        >
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.rogers}` });
            }}
          >
            Next project: A day as a Design System Designer
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
