import { WorkPage } from "../../components/WorkPageTemplate/WorkPage";
import {
  WorkPageType,
  getNavItems,
} from "../../components/WorkPageTemplate/navParts";
import { RBCDetails } from "./RBCDetails";

export const RBCPage: React.FC = () => {
  const navItems = getNavItems(WorkPageType.rbc);
  return (
    <WorkPage workPageType={WorkPageType.rbc} navItems={navItems}>
      <RBCDetails navItems={navItems} />
    </WorkPage>
  );
};
