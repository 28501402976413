import { WorkPage } from "../../components/WorkPageTemplate/WorkPage";
import {
  WorkPageType,
  getNavItems,
} from "../../components/WorkPageTemplate/navParts";
import { RogersDetails } from "./RogersDetails";

export const RogersPage: React.FC = () => {
  const navItems = getNavItems(WorkPageType.rogers);
  console.log({ navItems });
  return (
    <WorkPage workPageType={WorkPageType.rogers} navItems={navItems}>
      <RogersDetails navItems={navItems} />
    </WorkPage>
  );
};
