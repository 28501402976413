import { Box, Grid } from "@mui/material";

import React, { useMemo } from "react";

import { DetailsContent } from "./DetailsContent";
import { useNavigate } from "react-router-dom";
import { WorkPageType } from "../../components/WorkPageTemplate/navParts";
import { useCurrentBreakpoint } from "../../services";
import { WORK_PAGE_RESPONSIVE_DATA } from "../../components/WorkPageTemplate/WorkPage";
import {
  NextLink,
  SubLabel,
  TextWrapper,
  WorkHeader,
} from "../BMOPage/BMODetails.styled";
import { WorkDetailsProps } from "../BMOPage/BMODetails";

export const ICityPageDetails: React.FC<WorkDetailsProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        letterSpacing: "0.27px",
      }}
    >
      <WorkHeader sx={{ paddingY: responsiveUI.containerPaddingY }}>
        iCity - when design meets engineering{" "}
      </WorkHeader>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={3.5}>
          <SubLabel>ROLE</SubLabel>
          <TextWrapper>UX/UI Designer</TextWrapper>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <SubLabel>TIME</SubLabel>
          <TextWrapper>November, 2019 - June, 2020</TextWrapper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SubLabel>TEAM</SubLabel>
          <TextWrapper>
            OCAD U Visual Analytic Lab, Engineering department at UofT, 1
            Content Manager, 2 UX Designers, 1 Developer{" "}
          </TextWrapper>
        </Grid>
      </Grid>

      <DetailsContent navItems={navItems} />
      <Box
        sx={{
          textAlign: "right",
          margin: `${responsiveUI.containerPaddingY} 0`,
        }}
      >
        <Box>
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.withWomen}` });
            }}
          >
            Previous project: WITHWomen - Fights Intimate Partner Violence with
            technologies
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
