import { Box, styled } from "@mui/material";
import { StyledTypography } from "../../components/styles/styled-components";

export const IntroductionWrapper = styled(Box)<{ $containerPadding: string }>(
  (props) => ({
    justifyContent: "center",
    display: "flex",
    padding: props.$containerPadding,
  })
);

export const IntroText = styled(StyledTypography)<{ fontSize: string }>(
  ({ fontSize }) => ({
    fontSize,
    fontWeight: 700,
    lineHeight: `${parseInt(fontSize) * 1.4375}px`,
  })
);

export const IntroSpan = styled("span")({
  textDecoration: "underline",
});
