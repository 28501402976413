import { Box, Grid } from "@mui/material";

import React, { useMemo } from "react";

import { DetailsContent } from "./DetailsContent";
import { useNavigate } from "react-router-dom";
import { useCurrentBreakpoint } from "../../services";
import { WORK_PAGE_RESPONSIVE_DATA } from "../../components/WorkPageTemplate/WorkPage";
import {
  NextLink,
  SubLabel,
  TextWrapper,
  WorkHeader,
} from "../BMOPage/BMODetails.styled";
import { WorkDetailsProps } from "../BMOPage/BMODetails";

export const RogersDetails: React.FC<WorkDetailsProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        letterSpacing: "0.27px",
      }}
    >
      <div id="text-header">
        <WorkHeader sx={{ paddingY: responsiveUI.containerPaddingY }}>
          A day as a Design System Designer
        </WorkHeader>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={3.5}>
            <SubLabel>ROLE</SubLabel>
            <TextWrapper>Product Designer, Design System</TextWrapper>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <SubLabel>DURATION</SubLabel>
            <TextWrapper>2021 to 2022</TextWrapper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <SubLabel>TEAM</SubLabel>
            <TextWrapper>
              3 Product designers, 1 Accessibility specialist, 1 team lead,
              around 10 front-end developers, Back-end engineering team on
              consultancy
            </TextWrapper>
          </Grid>
        </Grid>
      </div>

      <DetailsContent navItems={navItems} />
      <Box
        sx={{
          textAlign: "right",
          margin: `${responsiveUI.containerPaddingY} 0`,
        }}
      >
        <Box>
          <NextLink
            onClick={() => {
              navigate({ pathname: "/bmo" });
            }}
          >
            Previous project: Filter, for daily financial convience
          </NextLink>
        </Box>

        <Box
          sx={{
            marginTop: `${parseInt(responsiveUI.containerPaddingY) / 3}px`,
          }}
        >
          <NextLink
            onClick={() => {
              navigate({ pathname: "/snc" });
            }}
          >
            Next project: Designing Digital Twin - the digital future of
            engineering
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
