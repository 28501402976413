import { WorkPage } from "../../components/WorkPageTemplate/WorkPage";
import {
  WorkPageType,
  getNavItems,
} from "../../components/WorkPageTemplate/navParts";
import { ICityPageDetails } from "./ICityPageDetails";

export const ICityPage: React.FC = () => {
  const navItems = getNavItems(WorkPageType.iCity);
  return (
    <WorkPage workPageType={WorkPageType.iCity} navItems={navItems}>
      <ICityPageDetails navItems={navItems} />
    </WorkPage>
  );
};
