import { Box, styled } from "@mui/material";
import { StyledTypography } from "../../components/styles/styled-components";
import { colors } from "../../common";

export const WorkHeader = styled(StyledTypography)({
  fontSize: "36px",
  fontWeight: 700,
});

export const TextWrapper = styled(StyledTypography)({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "27px",
});

export const SubLabel = styled(TextWrapper)({
  marginBottom: "16px",
  color: colors.grey,
});

export const PartTitle = styled(StyledTypography)({
  fontSize: "28px",
  fontWeight: 700,
  marginTop: "80px",
});

export const ContentText = styled(TextWrapper)({
  whiteSpace: "pre-line",
  margin: "24px 0",
});

export const PartSubTitle = styled(StyledTypography)({
  fontSize: "22px",
  fontWeight: 700,
  color: colors.grey,
  marginTop: "40px",
});

export const BoldText = styled("span")({
  fontWeight: 700,
});

export const HighlightBlock = styled(Box)({
  padding: "40px",
  backgroundColor: "#363636",
  fontSize: "20px",
  letterSpacing: "0.3px",
});

export const HighlightBlockText = styled(Box)<{
  noMarginBottom?: boolean;
}>(({ noMarginBottom }) => ({
  fontSize: "20px",
  whiteSpace: "pre-line",
  lineHeight: "30px",
  marginBottom: !noMarginBottom ? "24px" : undefined,
}));

export const PointItem = styled(StyledTypography)<{ $withMarginTop?: boolean }>(
  ({ $withMarginTop }) => ({
    fontSize: "20px",
    right: "20px",
    position: "relative",
    ":before": {
      content: '"•"',
      fontSize: "16px",
      padding: "0 7px",
    },
    ...($withMarginTop
      ? {
          whiteSpace: "pre-line",
          margin: "24px 0",
        }
      : {}),
  })
);

export const OrderList = styled("ol")<{
  boldMarker?: boolean;
}>(({ boldMarker }) => ({
  paddingLeft: "20px",
  "li::marker": {
    fontWeight: boldMarker ? 700 : undefined,
  },
}));

export const QuoteText = styled(Box)({
  paddingTop: "24px",
  fontSize: "18px",
  fontStyle: "italic",
  fontWeight: 500,
  lineHeight: "27px",
  letterSpacing: "0.27px",
  width: "60%",
  margin: "0 auto",
});

export const ImageCaption = styled(StyledTypography)({
  textAlign: "right",
  margin: 0,
  color: colors.grey,
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.21px",
  textTransform: "uppercase",
  maxWidth: "75%",
  marginLeft: "auto",
});

export const ImageComp = styled(Box)({
  margin: "24px 0",
});

export const NextLink = styled("a")({
  color: colors.white,
  fontSize: "18px",
  fontWeight: 700,
  letterSpacing: "0.27px",
  textDecorationLine: "underline",
  cursor: "pointer",
});
