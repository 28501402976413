import { Box, Grid, Link } from "@mui/material";
import { useMemo } from "react";
import { withPageWrapper } from "../../components/withPageWrapper";
import avatar from "../../assets/images/april-avatar.jpeg";
import { StyledTypography } from "../../components/styles/styled-components";
import {
  StyledText,
  SubLabel,
  ItemsContainer,
  Avatar,
} from "./AboutPage.styled";
import { Breakpoints, useCurrentBreakpoint } from "../../services";
import Resume from "../../assets/pdf/AprilYuResume_2024.pdf";
import { colors } from "../../common";

const ABOUT_PAGE_RESPONSIVE_DATA = {
  [Breakpoints.maximum]: {
    containerPadding: "80px 0px",
  },
  [Breakpoints.desktop]: {
    containerPadding: "80px 0px",
  },
  [Breakpoints.tablet]: {
    containerPadding: "40px 24px",
  },
  [Breakpoints.mobile]: {
    containerPadding: "24px 16px",
  },
};

const About = () => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => ABOUT_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );

  return (
    <Box
      sx={{
        width: "100%",
        padding: responsiveUI.containerPadding,
        margin: "0 auto",
      }}
    >
      <Grid container columnSpacing={{ xs: 2, sm: 3 }} rowSpacing={3}>
        <Grid item xs={0} sm={1} />
        <Grid item xs={12} sm={3}>
          <Box sx={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={12}>
                <Avatar src={avatar} alt="April's Avatar" />
              </Grid>
              <Grid item xs={6} sm={12}>
                <StyledTypography
                  sx={{ marginTop: "80px", fontSize: "18px", fontWeight: 500 }}
                >
                  <Link
                    sx={{
                      color: colors.white,
                      textDecoration: "underline !important",
                    }}
                    href={Resume}
                    target="_blank"
                  >
                    Latest Resume
                  </Link>
                </StyledTypography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={0} sm={1} />
        <Grid item xs={12} sm={6}>
          <StyledText
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            Hi, I'm April Yu, and I'm passionate about creating user-friendly
            workflows that enhance the daily lives of differently-abled
            individuals. {"\n"}
            {"\n"}
            My journey in design began in 2013 when, as a visual storytelling
            student at the time, I realized that making things aesthetically
            pleasing wasn't enough for me. In 2017, I took a bold step into the
            realm of Human-Computer Interaction, and I've been dedicated to
            User-Centred Design ever since. {"\n"}
            {"\n"}
            My work is rooted in the principles of consistency and
            accessibility, and I'm excited to collaborate with like-minded
            individuals to tackle complex problems and embrace new challenges.{" "}
            {"\n"}
            {"\n"}
            As an end-to-end Product Designer, I possess a unique enthusiasm for
            Design Systems and Accessibility. My skill set spans user research,
            sketching, wireframing, visual QA, user testing, and iterative
            design, all geared towards crafting inclusive and impactful
            experiences.
          </StyledText>
          <Grid container sx={{ marginTop: "80px" }} spacing={3}>
            <Grid item sm={12} md={4}>
              <SubLabel>Interests🧐:</SubLabel>
              <ItemsContainer>
                <StyledText>♻️ Design system</StyledText>
                <StyledText>📱 Mobile-first</StyledText>
                <StyledText>8️⃣ 8 pt grid system</StyledText>
                <StyledText>​🤝 Accessibility</StyledText>
              </ItemsContainer>
            </Grid>
            <Grid item sm={12} md={8}>
              <SubLabel>When I'm off design♨️, I'm a:</SubLabel>
              <ItemsContainer>
                <StyledText>📻 Addicted Podcast Listener</StyledText>
                <StyledText>🧘‍♀️ Heavily Meditator</StyledText>
                <StyledText>🚺 Feminism Advocate</StyledText>
                <StyledText>​🐱 Cat Lover</StyledText>
              </ItemsContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={1} />
      </Grid>
    </Box>
  );
};

export const AboutPage = withPageWrapper(About);
