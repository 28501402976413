import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  HeaderWrapper,
  NavItem,
  NavWrapper,
  TitleWrapper,
} from "./Header.styled";
import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { colors } from "../../common";
import { Divider } from "../Divider/Divider";
import { Breakpoints, useCurrentBreakpoint } from "../../services";
import { StyledLink } from "../styles/styled-components";

const StyledRouterLink = styled(Link)({
  textDecoration: "none",
  color: colors.white,
  ":visited": {
    color: colors.white,
  },
});

export const PAGE_WRAPPER_RESPONSIVE_DATA = {
  [Breakpoints.maximum]: {
    paddingX: "24px",
  },
  [Breakpoints.desktop]: {
    paddingX: "24px",
  },
  [Breakpoints.tablet]: {
    paddingX: "24px",
  },
  [Breakpoints.mobile]: {
    paddingX: "16px",
  },
};

export const Header = () => {
  const [height, setHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref?.current?.clientHeight) {
      return;
    }
    setHeight(ref?.current?.clientHeight);
  }, [ref?.current?.clientHeight]);

  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => PAGE_WRAPPER_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          backgroundColor: colors.black,
          maxWidth: "1440px",
          zIndex: 999,
        }}
        ref={ref}
      >
        <HeaderWrapper paddingX={responsiveUI.paddingX}>
          <TitleWrapper>
            <StyledRouterLink to="/">APRIL YU</StyledRouterLink>
          </TitleWrapper>
          <NavWrapper>
            <Box sx={{ marginRight: "32px" }}>
              <StyledRouterLink to="/">
                <NavItem>
                  <StyledLink>WORK</StyledLink>
                </NavItem>
              </StyledRouterLink>
            </Box>
            <Box>
              <StyledRouterLink to="/about">
                <NavItem>
                  <StyledLink>ABOUT</StyledLink>
                </NavItem>
              </StyledRouterLink>
            </Box>
          </NavWrapper>
        </HeaderWrapper>
        <Divider marginX={responsiveUI.paddingX} />
      </Box>

      <Box sx={{ height }} />
    </>
  );
};
