import { Box } from "@mui/material";
import React from "react";
import { colors } from "../../common";

interface Props {
  width?: string | number;
  marginX?: string;
}

export const Divider: React.FC<Props> = ({ width = "100%", marginX }) => (
  <Box
    sx={{
      borderTop: `1px solid ${colors.white}`,
      width: `calc(${width} - ${marginX} * 2)`,
      margin: "0 auto",
    }}
  ></Box>
);
