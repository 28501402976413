import { WorkPage } from "../../components/WorkPageTemplate/WorkPage";
import {
  WorkPageType,
  getNavItems,
} from "../../components/WorkPageTemplate/navParts";
import { SNCDetails } from "./SNCDetails";

export const SNCPage: React.FC = () => {
  const navItems = getNavItems(WorkPageType.snc);
  return (
    <WorkPage workPageType={WorkPageType.snc} navItems={navItems}>
      <SNCDetails navItems={navItems} />
    </WorkPage>
  );
};
