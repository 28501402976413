import { Link, Typography, styled } from "@mui/material";
import { colors } from "../../common";

export const StyledTypography = styled(Typography)({
  fontFamily: "'Raleway',sans-serif",
  lineHeight: "normal",
  color: colors.white,
});

export const StyledLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  paddingBottom: "2px",
  ":hover": {
    borderBottom: `1px solid ${colors.white}`,
  },
});
