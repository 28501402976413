import {
  PartTitle,
  ContentText,
  ImageComp,
  ImageCaption,
} from "../BMOPage/BMODetails.styled";

import MessagingCard from "../../assets/images/messaging-card.png";
import ListComponent from "../../assets/images/rbc-list-component.png";
import ShowYourPassword from "../../assets/images/rbc-show-your-password.png";
import DownloadVoidCheckFlow from "../../assets/images/rbc-download-void-check-flow.png";
import ViewYourStatementCompass from "../../assets/images/rbc-view-your-statement-compass.png";
import EmptyStates from "../../assets/images/rbc-empty-states.png";
import ErrorMessagesFolder from "../../assets/gif/rbc-error-messages-folder.gif";
import TechnicalError from "../../assets/gif/rbc-technical-error.gif";
import EmptyOffers from "../../assets/gif/rbc-empty-offers.gif";
import NoInternet from "../../assets/gif/rbc-no-internet.gif";
import YouthVideo from "../../assets/videos/rbc-youth-video.mp4";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";
import { Grid } from "@mui/material";
import { Breakpoints, useCurrentBreakpoint } from "../../services";
import { DetailsContentProps } from "../BMOPage/DetailsContent";

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();

  return (
    <>
      <div id={navItems[0].hash}>
        <PartTitle>Overview</PartTitle>
        <ContentText>
          During the summer in 2019, I worked as a coop student with RBC Mobile
          Team to shape up the mobile Design System and delivered essential
          flows.
        </ContentText>
      </div>

      <div>
        <PartTitle>Design System components</PartTitle>
        <ImageComp>
          <ImageContainer src={MessagingCard} alt="Messaging card" />
          <ImageCaption>
            Messaging card with video asset, designed and delivered
            independently
          </ImageCaption>
        </ImageComp>

        <ImageComp>
          <ImageContainer src={ListComponent} alt="List component" />
          <ImageCaption>
            list component with chevron, independently designed and delivered
          </ImageCaption>
        </ImageComp>
      </div>

      <div>
        <PartTitle>Icon & User flow Design</PartTitle>
        <ImageComp>
          <ImageContainer src={ShowYourPassword} alt="Show your password" />
          <ImageCaption>Show your password: icon and flow design </ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer
            src={DownloadVoidCheckFlow}
            alt="Download void cheque flow"
          />
          <ImageCaption>download void cheque flow design </ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer
            src={ViewYourStatementCompass}
            alt="View your statement compass"
          />
          <ImageCaption>
            View your statement compass icon and card design
          </ImageCaption>
        </ImageComp>
      </div>

      <div>
        <PartTitle>Illustration & Animations</PartTitle>

        <ImageComp>
          <ImageContainer src={EmptyStates} alt="Empty states" />

          <Grid container>
            <Grid item xs={12} sm={6}>
              <ImageContainer src={NoInternet} alt="No internet" $noMargin />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ImageContainer
                src={ErrorMessagesFolder}
                alt="Error message folder"
                $noMargin
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImageContainer src={EmptyOffers} alt="Empty offers" $noMargin />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImageContainer src={TechnicalError} alt="Technical error" />
            </Grid>
          </Grid>
          <ImageCaption>Empty states</ImageCaption>
        </ImageComp>

        <ImageComp>
          <div style={{ textAlign: "center", marginBottom: "8px" }}>
            <video
              width={currentBreakpoint === Breakpoints.mobile ? " 100%" : "50%"}
              src={YouthVideo}
              autoPlay
              muted
              loop
            />
          </div>
          <ImageCaption>RBC app youth edition promotion </ImageCaption>
        </ImageComp>
      </div>
    </>
  );
};
