import { Grid, styled } from "@mui/material";
import React, { useState } from "react";
import { StyledTypography } from "../../components/styles/styled-components";
import { useNavigate } from "react-router-dom";

interface Props {
  label: string;
  route: string;
  enableClick?: boolean;
  imageUrl: string;
}

const ImageWrapper = styled("img")({
  width: "100%",
  borderRadius: "5px",
});

const Overlay = styled(StyledTypography)({
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  fontSize: "28px",
  fontWeight: 700,
});

export const IntroItem: React.FC<Props> = ({
  label,
  enableClick,
  imageUrl,
  route,
}) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (enableClick) {
      navigate({ pathname: `/${route}` });
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid item xs={12} sm={6}>
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ position: "relative", borderRadius: "5px", cursor: "pointer" }}
        onClick={handleOnClick}
      >
        <ImageWrapper
          sx={{
            opacity: isHovered ? 0.3 : 1,
            transition: "opacity 0.2s ease",
          }}
          src={imageUrl}
        />
        {isHovered && <Overlay>Click to view</Overlay>}
      </div>

      <StyledTypography
        sx={{
          textAlign: "center",
          marginTop: "16px",
          fontSize: "20px",
          fontWeight: 500,
          cursor: enableClick ? "pointer" : undefined,
        }}
        onClick={handleOnClick}
      >
        {label}
      </StyledTypography>
    </Grid>
  );
};
