import { Box, styled } from "@mui/material";
import { colors } from "./common";

export const AppWrapper = styled(Box)({
  height: "100%",
  color: colors.white,
  fontFamily: "'Raleway', sans-serif",
  letterSpacing: "0.15px",
  maxWidth: "1440px",
  margin: "0 auto",
});
