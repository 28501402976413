import { Box, styled } from "@mui/material";
import { NextLink, WorkHeader } from "../BMOPage/BMODetails.styled";
import { WorkDetailsProps } from "../BMOPage/BMODetails";
import { useMemo } from "react";
import { WORK_PAGE_RESPONSIVE_DATA } from "../../components/WorkPageTemplate/WorkPage";
import { useCurrentBreakpoint } from "../../services";
import { useNavigate } from "react-router-dom";
import { DetailsContent } from "./DetailsContent";
import { WorkPageType } from "../../components/WorkPageTemplate/navParts";

const StyledCaption = styled(Box)({
  textAlign: "center",
  fontSize: 20,
  fontWeight: 500,
  marginTop: "40px",
});

export const BmoDsDetails: React.FC<WorkDetailsProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();

  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        letterSpacing: "0.27px",
      }}
    >
      <WorkHeader
        sx={{ paddingTop: responsiveUI.containerPaddingY, textAlign: "center" }}
      >
        BMO Secure DS work
      </WorkHeader>
      <StyledCaption>
        This page showcases part of the DS-related work I’ve done at BMO.
      </StyledCaption>
      <StyledCaption>
        For more detailed component design process, please go to Rogers page.
      </StyledCaption>

      <DetailsContent navItems={navItems} />

      <Box
        sx={{
          textAlign: "right",
          margin: `${responsiveUI.containerPaddingY} 0`,
        }}
      >
        <Box
          sx={{
            marginTop: `${parseInt(responsiveUI.containerPaddingY) / 3}px`,
          }}
        >
          <NextLink onClick={() => navigate(`/${WorkPageType.bmo}`)}>
            Next project: Filter, for daily financial convenience
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
