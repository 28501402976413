import { Box, Grid } from "@mui/material";
import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { DetailsContent } from "./DetailsContent";
import { WORK_PAGE_RESPONSIVE_DATA } from "../../components/WorkPageTemplate/WorkPage";
import { WorkPageType } from "../../components/WorkPageTemplate/navParts";
import { useCurrentBreakpoint } from "../../services";
import { SubLabel } from "../AboutPage/AboutPage.styled";
import {
  WorkHeader,
  TextWrapper,
  NextLink,
} from "../BMOPage/BMODetails.styled";
import { WorkDetailsProps } from "../BMOPage/BMODetails";

export const SNCDetails: React.FC<WorkDetailsProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        letterSpacing: "0.27px",
      }}
    >
      <WorkHeader sx={{ paddingY: responsiveUI.containerPaddingY }}>
        Designing Digital Twin - the digital future of engineering
      </WorkHeader>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={3.5}>
          <SubLabel>ROLE</SubLabel>
          <TextWrapper>UX/UI Designer</TextWrapper>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <SubLabel>DURATION</SubLabel>
          <TextWrapper>2020 to 2021</TextWrapper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SubLabel>TEAM</SubLabel>
          <TextWrapper>
            4 Visual designers, 4 Developers, 1 PO, Backend support team, 3D
            Design support team
          </TextWrapper>
        </Grid>
      </Grid>

      <DetailsContent navItems={navItems} />

      <Box
        sx={{
          textAlign: "right",
          margin: `${responsiveUI.containerPaddingY} 0`,
        }}
      >
        <Box>
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.rogers}` });
            }}
          >
            Previous project: A day as a Design System Designer
          </NextLink>
        </Box>

        <Box
          sx={{
            marginTop: `${parseInt(responsiveUI.containerPaddingY) / 3}px`,
          }}
        >
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.rbc}` });
            }}
          >
            Next project: First glance into the Design System world
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
