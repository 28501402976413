import { WorkPage } from "../../components/WorkPageTemplate/WorkPage";
import {
  WorkPageType,
  getNavItems,
} from "../../components/WorkPageTemplate/navParts";
import { BmoDsDetails } from "./BmoDsDetails";

export const BmoDsPage: React.FC = () => {
  const navItems = getNavItems(WorkPageType.bmoDs);
  return (
    <WorkPage workPageType={WorkPageType.bmoDs} navItems={navItems}>
      <BmoDsDetails navItems={navItems} />
    </WorkPage>
  );
};
