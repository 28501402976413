import {
  PartTitle,
  ContentText,
  ImageComp,
  ImageCaption,
  HighlightBlock,
  HighlightBlockText,
  OrderList,
  BoldText,
  PointItem,
  QuoteText,
  PartSubTitle,
} from "./BMODetails.styled";
import NewFilterMobile from "../../assets/images/new-filter-mobile.png";
import NewFilterDesk from "../../assets/images/new-filter-desktop.png";
import OldFilterBar from "../../assets/images/old-filter-bar.png";
import CompetitorAnalysis from "../../assets/images/competitor-analysis.png";
import MobileIdeation from "../../assets/images/mobile-ideations.png";
import DesktopIdeation1 from "../../assets/images/desktop-ideations-1.png";
import DesktopIdeation2 from "../../assets/images/desktop-ideations-2.png";
import TeamMapping from "../../assets/images/team-mapping.png";

import Ideation3 from "../../assets/images/ideation-3.png";
import UserTestingMaterial from "../../assets/images/user-testing-material.png";
import OldToNewFilter from "../../assets/gif/old-to-new-filter.gif";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";
import { NavItem } from "../../components/WorkPageTemplate/WorkPage";

export interface DetailsContentProps {
  navItems: NavItem[];
}

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  return (
    <>
      <div id={navItems[0].hash}>
        <PartTitle>Overview</PartTitle>
        <ContentText>
          In the BMO's Secure Retail Experience Design team, I serve as the lead
          designer for the Platform pod. Our responsibility is to oversee
          everything from individual components to overarching cross-LOB design
          patterns, guaranteeing a unified and seamless BMO online experience.
          {"\n"}
          {"\n"}
          Here, I want to highlight a key enhancement project I undertook at
          BMO. This project offers a glimpse into my methodical approach to
          pinpointing areas of enhancement, my comprehensive design journey, and
          the data-driven choices I navigated, while considering the balance of
          trade-offs and priorities.
        </ContentText>

        <ImageComp>
          <ImageContainer src={OldToNewFilter} alt="Old to new filter" />
        </ImageComp>
      </div>
      <div id={navItems[1].hash}>
        <PartTitle>Problem</PartTitle>
        <ContentText>
          Following BMO's significant digital banking overhaul in 2021, we've
          consistently analyzed monthly customer satisfaction surveys for the
          desktop interface. By January 2023, the primary feedback pointed
          towards the filter functionality on BMO's secure site as an area of
          concern for our users.{"\n"}
          {"\n"}
          The current filtering functionality displays all filter options but
          lacks interactivity. To engage with the filter bar, users must click
          the "filter" button to open a filter side panel. On mobile screen, it
          adapts to fit the screen and stacks the filter selections.
        </ContentText>
        <ImageComp>
          <ImageContainer src={OldFilterBar} alt="Old filter bar" />
          <ImageCaption>
            Screenshot of survey and colour coded findings
          </ImageCaption>
        </ImageComp>

        <HighlightBlock>
          <HighlightBlockText>
            From the monthly survey analysis, I pinpointed three primary issues
            with the filter function based on user feedback:
          </HighlightBlockText>
          <OrderList>
            <li>
              <HighlightBlockText>
                <BoldText>Discoverability:</BoldText>
                <PointItem>
                  Users are unaware the filter is interactive
                </PointItem>
                <QuoteText>
                  “The filter in the new website is very confusing, how am I
                  suppose to see my transactions last month?”
                </QuoteText>
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                <BoldText>Visibility & Cognitive load:</BoldText>
                <PointItem>
                  Users are aware the filter bar is interactive, but struggled
                  with how to adjust their selections.
                </PointItem>
                <QuoteText>
                  “Howcome everything is hidden? In *** (another bank)’s
                  website, I could change the filters in dropdowns.”
                </QuoteText>
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                <BoldText>
                  New feature (not pain point but exploration):
                </BoldText>
                <PointItem>
                  Users requested a feature to save their sorting preferences
                  for future logins
                </PointItem>
              </HighlightBlockText>
            </li>
          </OrderList>
        </HighlightBlock>

        <PartSubTitle>Goals</PartSubTitle>
        <ContentText>
          <BoldText>Desktop Goal: </BoldText>Make the current desktop filter
          more intuitive
        </ContentText>
        <ContentText>
          <BoldText>Mobile Goal: </BoldText>Create the mobile filter
        </ContentText>
      </div>

      <div id={navItems[2].hash}>
        <PartTitle>Process</PartTitle>
        <PartSubTitle>Research and defining the scope</PartSubTitle>
        <ContentText>
          My journey began with a competitor analysis, exploring filter
          functions across different financial institutions. Interestingly,
          BMO's filtering method, from the side sheet to displaying filter
          selections, aligns with the industry's 'common practice'.
        </ContentText>

        <ImageComp>
          <ImageContainer src={CompetitorAnalysis} alt="Competitor analysis" />
          <ImageCaption>Competitor Analysis</ImageCaption>
        </ImageComp>
        <ContentText>
          After consulting with my PO about our pod's capacity, I decided on an
          incremental enhancement approach. The goal is to address user pain
          points without overcomplicating the existing filter functionality.
        </ContentText>

        <PartSubTitle>Mobile first, please!</PartSubTitle>
        <ContentText>
          Despite feedback focusing on the desktop, I began ideation with a
          mobile-first mindset. Adhering to a mobile-first design principle has
          greatly benefited me. By compelling you to prioritize essential
          elements on a smaller screen, the transition to larger screens becomes
          naturally smoother. I started by evaluating the legacy BMO app's
          floating filter to consider its reuse potential. However, due to
          accessibility issues, particularly the risk of screen obstruction
          during zoom-ins, this was quickly ruled out.
        </ContentText>

        <HighlightBlock>
          <HighlightBlockText>
            From here, my thinking process is:
          </HighlightBlockText>
          <OrderList>
            <li>
              <HighlightBlockText>
                <BoldText>Retain</BoldText> the filter side panel to avoid extra
                dev work
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                Consider desktop as expanded and mobile as{" "}
                <BoldText>condensed</BoldText>
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                Maintain the filter button's{" "}
                <BoldText>prominence on mobile</BoldText> as it currently is
              </HighlightBlockText>
            </li>
          </OrderList>
        </HighlightBlock>

        <ContentText>From here, I drafted 3 ideas:</ContentText>
        <ImageComp>
          <ImageContainer src={MobileIdeation} alt="Mobile Ideation" />
          <ImageCaption>Mobile ideations</ImageCaption>
        </ImageComp>

        <ContentText>My ideation progressed step-by-step:</ContentText>
        <OrderList sx={{ fontSize: "18px" }}>
          <li>
            First, I thought of utilizing the filter button on mobile filter bar
            to allow users to access filter side sheet.
          </li>
          <li>
            Next, I saw the importance of giving feedback when users selected
            new filters, leading me to introduce a red dot.
          </li>
          <li>
            Eventually, I recognized the potential for the dot to also display a
            numerical count of the applied filters. As this makes the filter
            button much more prominent, the chevron is not needed anymore.
          </li>
        </OrderList>

        <ContentText>
          In our peer review, we collectively agreed that the version with the
          numerical dot was the best choice. While we unfortunately didn't
          allocate time for mobile-specific user testing in this project,
          feedback in August from our first-time, 5000-people mobile pilot was
          reassuring, with only three comments pertaining to the filter
          functions.
        </ContentText>

        <PartSubTitle>Desktop ideation</PartSubTitle>
        <ContentText>
          After finalizing the mobile design, I shifted my focus to
          conceptualizing the desktop version. Armed with deeper insights into
          user pain points, my goal was to ensure the desktop design solves
          users’ concerns while aligning to the mobile one.
        </ContentText>

        <HighlightBlock>
          <HighlightBlockText>
            For the desktop design, my ideation priorities include:{" "}
          </HighlightBlockText>
          <OrderList>
            <li>
              <HighlightBlockText>
                <BoldText>Increasing access points</BoldText> to the filter side
                panel, the hub of main interactions.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                Making interaction features <BoldText>more prominent</BoldText>{" "}
                through clear UI elements.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                Enabling straightforward actions, like removing filters directly
                from the filter bar.
              </HighlightBlockText>
            </li>
            <HighlightBlockText>
              All of these priorities are tackling the discoverability and
              visibility issues found in customer feedback, with an enhancement
              on reducing user’ cognitive load.
            </HighlightBlockText>
          </OrderList>
        </HighlightBlock>

        <ContentText>I drafted 4 ideations:</ContentText>
        <ImageComp>
          <ImageContainer src={DesktopIdeation1} alt="Desktop ideation 1" />
          <ImageContainer src={DesktopIdeation2} alt="Desktop ideation 2" />

          <ImageCaption>4 Initial ideations</ImageCaption>
        </ImageComp>

        <ContentText>
          A glimpse into how I work with my team: I maintain close collaboration
          with both my pod and the larger design team. As I brainstorm, I
          consistently engage my PO, a couple of developer colleagues, an
          accessibility specialist, and a design system designer. Early
          involvement of these stakeholders is essential in my process—it
          proactively surfaces potential hurdles and conflicts. For medium-scale
          module redesigns like this filter bar, I present to my peers once the
          ideations reach a medium-fidelity stage. From there, we have a
          passionate debate (a friendly "fight" 😉) to determine the best
          direction forward.
        </ContentText>

        <ImageComp>
          <ImageContainer src={TeamMapping} alt="Team mapping" />
          <ImageCaption>Team mapping</ImageCaption>
        </ImageComp>

        <HighlightBlock>
          <HighlightBlockText>
            After discussions with my team, I've decided to move forward with
            ideation 3, based on the following reasons:
          </HighlightBlockText>
          <OrderList boldMarker>
            <li>
              <HighlightBlockText>
                <BoldText>Low Impact on Current DS Component: </BoldText>
                {"\n"}
                Ideation 3 fits our incremental change approach, needing only
                slight adjustments to the current DS component.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                <BoldText>
                  Better Visibility and Usability of Filter Bar:
                </BoldText>
                {"\n"}
                The design enhances filter interactions through clear icons and
                straightforward actions.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                <BoldText>Efficient Development Process:</BoldText>
                {"\n"}
                Ideation 2 promotes smooth implementation without added
                developmental challenges.
              </HighlightBlockText>
            </li>
          </OrderList>
          <HighlightBlockText>
            Post discussions with my development team and after evaluating
            different alternatives, we concluded that retaining user sorting
            preferences for subsequent logins isn't feasible at this point.
            Thus, I've halted further exploration on this.
          </HighlightBlockText>
        </HighlightBlock>

        <ContentText>
          In a subsequent iteration for NO.3, I removed the right and left
          scroll chevrons. I made this change because the filter bar is already
          a high-traffic module. Eliminating the scrolling function and
          truncating the chips can further enhance the visibility of all filter
          options at a single glance.
        </ContentText>

        <ImageComp>
          <ImageContainer src={Ideation3} alt="Ideation No.3 after iteration" />
          <ImageCaption>Ideation NO.3 after iteration</ImageCaption>
        </ImageComp>

        <PartSubTitle>Refine and test</PartSubTitle>
        <ContentText>
          While I iterated ideation NO.3, the DS team recommended switching from
          the micro button to a chip. I concurred for the following reasons:
        </ContentText>

        <OrderList boldMarker>
          <li>
            <ContentText>
              <BoldText>User Familiarity: </BoldText>
              {"\n"}
              Chips are commonly associated with filtering, offering a more
              intuitive experience than micro buttons.
            </ContentText>
          </li>
          <li>
            <ContentText>
              <BoldText>Consistency with Design System: </BoldText>
              {"\n"}
              The shift aligns perfectly with our Design System's hierarchy, an
              aspect I prioritize.
            </ContentText>
          </li>
        </OrderList>

        <ContentText>
          Collaborating with my research colleague, we crafted specific
          questions to evaluate the usability of my design. To ensure unbiased
          feedback, I masked branding elements and spread my prototypes across
          two distinct URLs during the test. After securing 20 qualified
          participants and receiving their feedback, I tagged and analyzed the
          research data.
        </ContentText>

        <ImageComp>
          <ImageContainer
            src={UserTestingMaterial}
            alt="User testing material"
          />
          <ImageCaption>user testing material</ImageCaption>
        </ImageComp>

        <ContentText>
          If I elaborate on the user testing results, I’d need a whole new
          project page for that 😛. So in short, the user testing results
          validated my proposed design, showcasing notably smoother, faster, and
          more intuitive user interactions:
        </ContentText>

        <HighlightBlock>
          <PointItem $withMarginTop>
            <BoldText>70%</BoldText> of users accessed the filter side panel
            from one of the filter chips
          </PointItem>

          <PointItem $withMarginTop>
            When asked to remove <BoldText>one</BoldText> of the user-selected
            filter, <BoldText>40%</BoldText>
            participants removed it on the filter bar
          </PointItem>

          <PointItem $withMarginTop>
            When asked to remove <BoldText>all</BoldText> filters,{" "}
            <BoldText>37%</BoldText> used the remove button on the filter bar,
            while <BoldText>32%</BoldText> participants removed them one by one
            on the filter bar.
          </PointItem>
        </HighlightBlock>

        <ContentText>
          If you’re interested to see more of my research results, please view
          this slide here.
        </ContentText>
      </div>

      <div id={navItems[3].hash}>
        <PartTitle>Solution 🎉</PartTitle>
        <ImageComp>
          <ImageContainer src={NewFilterMobile} alt="New filter mobile" />
          <ImageCaption>New filter bar on mobile</ImageCaption>
        </ImageComp>
        <ImageComp>
          <ImageContainer src={NewFilterDesk} alt="New filter desktop" />
          <ImageCaption>New filter bar on desktop</ImageCaption>
        </ImageComp>
      </div>

      <div id={navItems[4].hash}>
        <PartTitle>Key metrics</PartTitle>
        <ContentText>
          While we did observe a decline in user complaints about the filter
          functionality post-January, it still lingered among the top 10
          criticisms about the BMO secure site. However, after the pilot launch
          of the enhanced filter bar, criticisms plummeted, moving from around{" "}
          <BoldText>20</BoldText>
          comments monthly to just <BoldText>1</BoldText>.
        </ContentText>
        <ContentText>
          It's truly gratifying to see the acknowledgment of my efforts on the
          BMO revamp project, especially when I come across comments like these:
        </ContentText>
        <QuoteText>
          “I would like to express my gratitude for the recent updates. I had
          been considering changing banks because of the confusions due to the
          new website, but now I can recommend BMO to my family and friends
          again. ”
        </QuoteText>
      </div>

      <div id={navItems[5].hash}>
        <PartTitle>Takeaways and future iterations</PartTitle>
        <PartSubTitle>1. Takeaways</PartSubTitle>
        <ContentText>
          <BoldText>Integrating user feedback is essential: </BoldText>
          {"\n"}
          In our monthly survey, we asked users to rate our recently launched
          website and provide reasons. Thanks to this approach, I was able to
          quickly identify user pain points and addressed them through
          incremental changes without a user research that could potentially
          takes months.
        </ContentText>
        <ContentText>
          <BoldText>Minimizing cognitive load is crucial: </BoldText>
          {"\n"}
          By presenting filter selections as interactive chips, we simplified
          the user's cognitive load from "to change a filter selection, click
          the filter button" to "to change a filter selection, just click it."
        </ContentText>
        <ContentText>
          <BoldText>Prioritize mobile optimization: </BoldText>
          {"\n"}
          Unfortunately, BMO's website revamp project prioritized desktop design
          over mobile, resulting in redundant work for both mobile and desktop
          platforms like in this project.
        </ContentText>
        <PartSubTitle>2. Future iterations</PartSubTitle>
        <ContentText>
          <BoldText>Opportunities for Content: </BoldText>
          {"\n"}
          During the user testing, I observed that some users found the
          financial terms "debit" and "credit" in the Credit Card transaction
          history confusing. I recommended to our content designers that we
          could simplify these terms to "Money out" and "Money in."
        </ContentText>
        <ContentText>
          <BoldText>Opportunities for the Filter Side Sheet: </BoldText>
          {"\n"}
          From internal testing during the desktop pilot release, I observed
          that users with smaller desktop displays and those who zoom in for
          better readability may have difficulty locating the 'Filter' and
          'Reset' buttons at the bottom of the filter side panel. I am
          interested in exploring potential solutions to either reduce the
          height of the content within the filter side panel or relocate the CTA
          buttons to the top of the panel for enhanced visibility.
        </ContentText>
      </div>
    </>
  );
};
