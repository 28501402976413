import { Box, Grid } from "@mui/material";
import {
  SubLabel,
  TextWrapper,
  WorkHeader,
  NextLink,
} from "../BMOPage/BMODetails.styled";
import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { WorkPageType } from "../../components/WorkPageTemplate/navParts";
import { useCurrentBreakpoint } from "../../services";
import { WORK_PAGE_RESPONSIVE_DATA } from "../../components/WorkPageTemplate/WorkPage";
import { DetailsContent } from "./DetailsContent";
import { WorkDetailsProps } from "../BMOPage/BMODetails";

export const RBCDetails: React.FC<WorkDetailsProps> = ({ navItems }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        letterSpacing: "0.27px",
      }}
    >
      <WorkHeader sx={{ paddingY: responsiveUI.containerPaddingY }}>
        First glance into the Design System world{" "}
      </WorkHeader>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={3.5}>
          <SubLabel>ROLE</SubLabel>
          <TextWrapper>Visual Experience Designer</TextWrapper>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <SubLabel>TIME</SubLabel>
          <TextWrapper>May - Sept, 2019</TextWrapper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SubLabel>TEAM</SubLabel>
          <TextWrapper>
            RBC Mobile team (10+ designers), front-end developers, business team
          </TextWrapper>
        </Grid>
      </Grid>

      <DetailsContent navItems={navItems} />

      <Box
        sx={{
          textAlign: "right",
          margin: `${responsiveUI.containerPaddingY} 0`,
        }}
      >
        <Box>
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.snc}` });
            }}
          >
            Previous project: Designing Digital Twin - the digital future of
            engineering
          </NextLink>
        </Box>

        <Box
          sx={{
            marginTop: `${parseInt(responsiveUI.containerPaddingY) / 3}px`,
          }}
        >
          <NextLink
            onClick={() => {
              navigate({ pathname: `/${WorkPageType.withWomen}` });
            }}
          >
            Next project: WITHWomen - Fights Intimate Partner Violence with
            technologies
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
