import { Box, Grid, Stack, styled } from "@mui/material";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";

import InformationArchitecture from "../../assets/images/with-women-information-architecture.png";
import IteratedArchitecture1 from "../../assets/images/with-women-iterated-interfaces-1.png";
import IteratedArchitecture2 from "../../assets/images/with-women-iterated-interfaces-2.png";
import IteratedArchitecture3 from "../../assets/images/with-women-iterated-interfaces-3.png";
import IteratedArchitecture4 from "../../assets/images/with-women-iterated-interfaces-4.png";

import {
  ContentText,
  ImageComp,
  ImageCaption,
  NextLink,
} from "../BMOPage/BMODetails.styled";
import { DetailsContentProps } from "../BMOPage/DetailsContent";

const AppLinkText = styled(ContentText)<{ $isLink?: boolean }>(
  ({ $isLink }) => ({
    margin: "16px 0",
    fontWeight: 700,
    textDecoration: $isLink ? "underline" : "none",
    cursor: $isLink ? "pointer" : "default",
  })
);

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  return (
    <>
      <Stack direction="row" margin="80px 0">
        <Box>
          <AppLinkText>Click here to view</AppLinkText>
        </Box>
        <Box marginLeft="8px">
          <AppLinkText $isLink>
            <NextLink href="https://withwomen.ca/" target="_blank">
              WITHWomen webapp
            </NextLink>
          </AppLinkText>
          <AppLinkText $isLink>
            <NextLink
              href="https://withwomenpathways.ca/en/home"
              target="_blank"
            >
              WithWomen Pathway
            </NextLink>
          </AppLinkText>
        </Box>
      </Stack>
      <div id={navItems[0].hash}>
        <ImageComp>
          <ImageContainer
            src={InformationArchitecture}
            alt="Information architecture"
          />
          <ImageCaption>INFORMATION ARCHITECTURE</ImageCaption>
        </ImageComp>

        <ImageComp>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ImageContainer
                src={IteratedArchitecture3}
                alt="Empty offers"
                $noMargin
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ImageContainer
                src={IteratedArchitecture1}
                alt="No internet"
                $noMargin
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ImageContainer
                src={IteratedArchitecture2}
                alt="Error message folder"
                $noMargin
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ImageContainer
                src={IteratedArchitecture4}
                alt="Technical error"
              />
            </Grid>
          </Grid>
          <ImageCaption>Empty states</ImageCaption>
        </ImageComp>
      </div>
    </>
  );
};
