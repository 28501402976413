import { createHashRouter, redirect } from "react-router-dom";
import { AboutPage } from "../pages/AboutPage/AboutPage";
import { LandingPage } from "../pages/LandingPage/LandingPage";
import { WorkPageType } from "../components/WorkPageTemplate/navParts";
import { BMOPage } from "../pages/BMOPage/BMOPage";
import { SNCPage } from "../pages/SNCPage/SNCPage";
import { RogersPage } from "../pages/RogersPage/RogersPage";
import { RBCPage } from "../pages/RBCPage/RBCPage";
import { WithWomenPage } from "../pages/WithWomenPage/WithWomenPage";
import { ICityPage } from "../pages/ICityPage/ICityPage";
import { BmoDsPage } from "../pages/BmoDsPage/BmoDsPage";

export const router = createHashRouter([
  {
    path: "/",
    errorElement: <LandingPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/work",
        loader: () => redirect("/"),
      },
      {
        path: `/${WorkPageType.bmo}`,
        element: <BMOPage />,
      },
      {
        path: `/${WorkPageType.snc}`,
        element: <SNCPage />,
      },
      {
        path: `/${WorkPageType.rogers}`,
        element: <RogersPage />,
      },
      {
        path: `/${WorkPageType.rbc}`,
        element: <RBCPage />,
      },
      {
        path: `/${WorkPageType.withWomen}`,
        element: <WithWomenPage />,
      },
      {
        path: `/${WorkPageType.iCity}`,
        element: <ICityPage />,
      },
      {
        path: `/${WorkPageType.bmoDs}`,
        element: <BmoDsPage />,
      },
    ],
  },
]);
