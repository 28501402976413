import { useMemo } from "react";
import { withPageWrapper } from "../../components/withPageWrapper";
import { Box, Grid } from "@mui/material";
import { IntroItem } from "./IntroItem";
import {
  IntroductionWrapper,
  IntroSpan,
  IntroText,
} from "./LandingPage.styled";
import { Breakpoints, useCurrentBreakpoint } from "../../services";
import BmoLandingPage from "../../assets/images/bmo-landing-page.png";
import RogersCard from "../../assets/images/rogers-card.png";
import SNCCard from "../../assets/images/snc-card.png";
import RBCCard from "../../assets/images/rbc-card.png";
import WithWomenCard from "../../assets/images/with-women-card.png";
import BMODSThumbnil from "../../assets/images/BMO-DS-thumbnail.png";
import ICityCard from "../../assets/images/icity-card.png";

import { WorkPageType } from "../../components/WorkPageTemplate/navParts";

export const PAGE_RESPONSIVE_DATA = {
  [Breakpoints.maximum]: {
    contentWidth: "920px",
    headerFontSize: "32px",
    headerBottomSpace: "120px",
    containerPadding: "120px 0px",
  },
  [Breakpoints.desktop]: {
    contentWidth: "65%",
    headerFontSize: "32px",
    headerBottomSpace: "120px",
    containerPadding: "120px 0px",
  },
  [Breakpoints.tablet]: {
    contentWidth: "100%",
    headerFontSize: "32px",
    headerBottomSpace: "80px",
    containerPadding: "80px 24px",
  },
  [Breakpoints.mobile]: {
    contentWidth: "100%",
    headerFontSize: "22px",
    headerBottomSpace: "40px",
    containerPadding: "40px 16px 24px",
  },
};

const Landing = () => {
  const currentBreakpoint = useCurrentBreakpoint();

  const responsiveUI = useMemo(
    () => PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );

  return (
    <>
      <IntroductionWrapper $containerPadding={responsiveUI.containerPadding}>
        <Box sx={{ width: responsiveUI.contentWidth }}>
          <Box sx={{ paddingBottom: responsiveUI.headerBottomSpace }}>
            <IntroText fontSize={responsiveUI.headerFontSize}>
              Hi 👋, I’m April,
            </IntroText>
            <IntroText fontSize={responsiveUI.headerFontSize}>
              a Product Designer with expertise in{" "}
              <IntroSpan>Design System</IntroSpan> and{" "}
              <IntroSpan>Platform</IntroSpan> work.
            </IntroText>
            <IntroText fontSize={responsiveUI.headerFontSize}>
              I'm also good at:
            </IntroText>

            <Box marginTop="40px">
              <IntroText fontSize={responsiveUI.headerFontSize}>
                📱 Mobile design
              </IntroText>
              <IntroText fontSize={responsiveUI.headerFontSize}>
                ✅ Accessibility & Inclusion
              </IntroText>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={"24px"}>
              <IntroItem
                route={WorkPageType.bmoDs}
                label="BMO Design System Work"
                enableClick
                imageUrl={BMODSThumbnil}
              />
              <IntroItem
                route={WorkPageType.bmo}
                label="BMO"
                enableClick
                imageUrl={BmoLandingPage}
              />
              <IntroItem
                route={WorkPageType.rogers}
                label="ROGERS"
                enableClick
                imageUrl={RogersCard}
              />
              <IntroItem
                route={WorkPageType.snc}
                label="SNC Lavalin"
                enableClick
                imageUrl={SNCCard}
              />
              <IntroItem
                route={WorkPageType.rbc}
                label="RBC"
                enableClick
                imageUrl={RBCCard}
              />
              <IntroItem
                route={WorkPageType.withWomen}
                label="WithWomen"
                enableClick
                imageUrl={WithWomenCard}
              />
              <IntroItem
                route={WorkPageType.iCity}
                label="iCity"
                enableClick
                imageUrl={ICityCard}
              />
            </Grid>
          </Box>
        </Box>
      </IntroductionWrapper>
    </>
  );
};
export const LandingPage = withPageWrapper(Landing);
