import RogersWorkflow from "../../assets/images/rogers-workflow.png";
import ConfluencePage from "../../assets/images/confluence-page.png";
import ResearchOnPromotionCard from "../../assets/images/research-promotion-card.png";
import MeetingStructure from "../../assets/images/meeting-structure.png";
import ComponentAndLayers from "../../assets/images/component-and-layers.png";
import ComponentDocumentation1 from "../../assets/images/component-documentation-1.png";
import ComponentDocumentation2 from "../../assets/images/component-documentation-2.png";
import ComponentDocumentation3 from "../../assets/images/component-documentation-3.png";

import RogersAndFido from "../../assets/images/rogers-and-fido.png";

import { ReactComponent as OrangeBlueCircle } from "../../assets/svg/orange-blue-circle.svg";
import { ReactComponent as OrangeCircle } from "../../assets/svg/orange-circle.svg";
import { ReactComponent as BlueCircle } from "../../assets/svg/blue-circle.svg";
import { Box, styled } from "@mui/material";
import { ImageContainer } from "../../components/ImageContainer/ImageContainer";
import {
  PartTitle,
  ContentText,
  BoldText,
  ImageComp,
  ImageCaption,
  OrderList,
  HighlightBlock,
  HighlightBlockText,
} from "../BMOPage/BMODetails.styled";
import { DetailsContentProps } from "../BMOPage/DetailsContent";

const circlePointTextStyle = {
  position: "relative",
  paddingLeft: "32px",
};

const CircleWrapper = styled(Box)({
  position: "absolute",
  left: 0,
});

export const DetailsContent: React.FC<DetailsContentProps> = ({ navItems }) => {
  return (
    <>
      <div id={navItems[0].hash}>
        <PartTitle>Overview</PartTitle>
        <ContentText>
          I’ve been giving some thought to how to showcase my experience as a
          Design System Product Designer at Rogers. My concerns are that, while
          the industry prefers to see large-scale, end-to-end projects, as
          Design System Designers, we often deal with small to medium scope
          projects within fast iterations. I believe it’s more authentic to show
          these projects rather than an extended one.
          {"\n"}
          {"\n"}I also believe that as a Design System designer, the ability to
          emphasize and communicate with your colleagues / users is just as
          important as delivering components.
          {"\n"}
          {"\n"}
          Hence, I've shaped this case study to showcase these 2 aspects:
        </ContentText>

        <ContentText sx={circlePointTextStyle}>
          <CircleWrapper>
            <BlueCircle />
          </CircleWrapper>
          <BoldText>1. Craft skills: </BoldText>Diving into the specifics of
          component design.
        </ContentText>
        <ContentText sx={circlePointTextStyle}>
          <CircleWrapper>
            <OrangeCircle />
          </CircleWrapper>
          <BoldText>2. Soft skills: </BoldText>Detailing my approach to
          addressing requests from LOB designers, managing two brands, and
          communicating our guidelines effectively.
        </ContentText>
      </div>
      <div id={navItems[1].hash}>
        <PartTitle>Working process</PartTitle>
        <ImageComp>
          <ImageContainer src={RogersWorkflow} alt="Rogers workflow" />
          <ImageCaption>workflow at a glance</ImageCaption>
        </ImageComp>
        <ContentText>
          Before we begin, have a look at my working process for delivering a
          component. I’ll break up each step in the following case study.
        </ContentText>
      </div>

      <div id={navItems[2].hash}>
        <PartTitle sx={circlePointTextStyle}>
          <CircleWrapper>
            <OrangeCircle />
          </CircleWrapper>
          Intake tickets
        </PartTitle>
        <ContentText>
          Here, I’m going to use Promotion card component as an example to walk
          you through my day as a Design System designer:
        </ContentText>

        <ImageComp>
          <ImageContainer
            src={ConfluencePage}
            alt="Confluence page screenshot"
          />
          <ImageCaption>Confluence page screenshot</ImageCaption>
        </ImageComp>
        <ContentText>
          The first step to understand the request is always through the intake
          form, I consider this skill as a soft skill since though it doesn’t
          directly help us to craft the component, it helps us to efficiently
          gather requests and guide the owners to identify what they truly need
          and absolutely don’t want.
        </ContentText>
      </div>

      <div id={navItems[3].hash}>
        <PartTitle sx={circlePointTextStyle}>
          <CircleWrapper>
            <OrangeBlueCircle />
          </CircleWrapper>
          DS internal meeting
        </PartTitle>
        <ContentText>
          The second step is to have an Design System squad internal meeting to
          assess the ticket. During the meeting, we ask 3 questions:{" "}
        </ContentText>
        <ContentText>
          <OrderList>
            <li>Is this component worth making global?</li>
            <li>Do we have similar components already available?</li>
            <li>Is our team the right one to build this component?</li>
          </OrderList>
        </ContentText>
        <ContentText>
          I consider this part a mix of craft and soft skills, the craft skills
          are:
        </ContentText>

        <HighlightBlock>
          <ul>
            <li>
              <HighlightBlockText>
                When taking in new requests, it requires a good memory and
                strong focus to quickly scan the design library in mind, these
                are abilities I proudly possess.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                When assessing a ticket without a proposed solution, it requires
                us to quickly research and wireframe.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                When assessing a ticket with a proposed design, we need to
                review the design in a systematic way to examine if the
                component fits in as a global component.
              </HighlightBlockText>
            </li>
          </ul>
        </HighlightBlock>

        <ContentText>The soft skills are:</ContentText>
        <HighlightBlock>
          <ul>
            <li>
              <HighlightBlockText>
                We hold internal meetings in turns, apart from the presentation
                skills, this demands strong communication skills because we’re
                also responsible for coordinating with the component owners,
                providing advice if the component doesn’t pass any requirements,
                and thoroughly documenting everything.
              </HighlightBlockText>
            </li>
          </ul>
        </HighlightBlock>

        <ImageComp>
          <ImageContainer
            src={ResearchOnPromotionCard}
            alt="Research on promotion card"
          />
          <ImageCaption>research on promotion card</ImageCaption>
        </ImageComp>
      </div>

      <div id={navItems[4].hash}>
        <PartTitle sx={circlePointTextStyle}>
          <CircleWrapper>
            <OrangeCircle />
          </CircleWrapper>
          ‘Shape up’ the component!
        </PartTitle>
        <ContentText>
          In this step, we complete the Intake form with the component owner to
          get a clear and defined direction, we’ll focus on the later half of
          the form, from solutions to component directions.
        </ContentText>

        <HighlightBlock>
          <ul>
            <li>
              <HighlightBlockText>
                When taking in new requests, it requires a good memory and
                strong focus to quickly scan the design library in mind, these
                are abilities I proudly possess.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                When assessing a ticket without a proposed solution, it requires
                us to quickly research and wireframe.
              </HighlightBlockText>
            </li>
            <li>
              <HighlightBlockText>
                When assessing a ticket with a proposed design, we need to
                review the design in a systematic way to examine if the
                component fits in as a global component.
              </HighlightBlockText>
            </li>
          </ul>
        </HighlightBlock>

        <ContentText>The soft skills are:</ContentText>

        <HighlightBlock>
          <ul>
            <li>
              <HighlightBlockText>
                We hold internal meetings in turns, apart from the presentation
                skills, this demands strong communication skills because we’re
                also responsible for coordinating with the component owners,
                providing advice if the component doesn’t pass any requirements,
                and documenting everything.
              </HighlightBlockText>
            </li>
          </ul>
        </HighlightBlock>

        <ImageComp>
          <ImageContainer src={MeetingStructure} alt="Meeting structure" />
          <ImageCaption>meeting structure</ImageCaption>
        </ImageComp>
      </div>

      <div id={navItems[5].hash}>
        <PartTitle sx={circlePointTextStyle}>
          <CircleWrapper>
            <BlueCircle />
          </CircleWrapper>
          Building the component
        </PartTitle>
        <ContentText>
          Here comes my favourite part - component building!
        </ContentText>
        <ContentText>
          I enjoy the simplicity of crafting every colour and every fonts into
          the perfect balance, to me, it’s no different than a meditation.
        </ContentText>

        <ImageComp>
          <ImageContainer src={ComponentAndLayers} alt="Component and layers" />
          <ImageCaption>Component and layers</ImageCaption>
        </ImageComp>

        <ContentText>
          Several things I pay extra attentions to when I build a component:
        </ContentText>

        <HighlightBlock>
          <OrderList>
            <li>Naming convention</li>
            <li>Proper uses of Auto-layout</li>
            <li>Uses of property</li>
          </OrderList>
        </HighlightBlock>

        <ContentText>
          I love (and only accept) my components to be clean, logical and
          concise. An unnecessary frame naming ‘Frame 1086’ would be my deep
          regret 🙅‍♀️. Just like clean code is develper’s standard, ‘clean
          components’ is mine.
        </ContentText>
      </div>

      <div id={navItems[6].hash}>
        <PartTitle sx={circlePointTextStyle}>
          <CircleWrapper>
            <BlueCircle />
          </CircleWrapper>
          Documentation and Dev hand-off
        </PartTitle>
        <ContentText>
          To me, documenting a component properly is just as important as
          creating one. Below is an example of my component documentation.
        </ContentText>

        <ImageComp>
          <ImageContainer
            src={ComponentDocumentation1}
            alt="Component documentation 1"
            $noMargin
          />
          <ImageContainer
            src={ComponentDocumentation2}
            alt="Component documentation 2"
            $noMargin
          />
          <ImageContainer
            src={ComponentDocumentation3}
            alt="Component documentation 3"
          />
          <ImageCaption>Component Documentation</ImageCaption>
        </ImageComp>

        <HighlightBlock>
          The goal of my component documentation is to ensure that a designer
          doesn't need to contact me or anyone else to learn about the
          component. Everything they need to understand and follow should be
          included in the documentation.
        </HighlightBlock>
      </div>

      <div id={navItems[7].hash}>
        <PartTitle sx={circlePointTextStyle}>
          <CircleWrapper>
            <OrangeBlueCircle />
          </CircleWrapper>
          Visual QA and follow-up questions
        </PartTitle>
        <ContentText>
          At Rogers, the Design System squad manages two distinct brands: Rogers
          and Fido. This means that I need to be sharp about the details of each
          brand and answer questions from visual QA even after some time has
          passed since the component was delivered.
        </ContentText>
        <ContentText>
          Below is an example of the similarities and differences of the
          Promotion card component.
        </ContentText>

        <ImageComp>
          <ImageContainer src={RogersAndFido} alt="Rogers and fido" />
          <ImageCaption>Rogers and fido, an example</ImageCaption>
        </ImageComp>

        <ContentText>
          After delivering the Fido component using Fido-specific colours,
          styles and branding, the creation of the component would be finalized.
          But do not consider this as the end point, it is the beginning of a
          new iteration.
        </ContentText>
      </div>

      <div id={navItems[8].hash}>
        <PartTitle>At the end of the ‘day’</PartTitle>
        <ContentText>
          As early as during my internship at RBC in 2019, I discovered that
          Design System and Platform work will play a very important role in my
          career. I find joy in working on Design Systems because of the
          inherent simplicity of it, There's a particular satisfaction when all
          elements seamlessly align and harmonize. Also, a good,
          designer-friendly Design System can boost my fellow coworkers’ work so
          much. It really gives me a sense of fulfillment when I hear my
          coworkers say: ‘Hey! That’s neat!’
        </ContentText>
        <ContentText>
          They say ‘a little kindness goes a long way’, I say ‘a little passion
          can burst into a great deal of shapes‘! 😛
        </ContentText>
      </div>
    </>
  );
};
