import { NavItem } from "./WorkPage";

const bmoNavItems: NavItem[] = [
  {
    hash: "overview",
    label: "Overview",
  },
  {
    hash: "problem",
    label: "Problem",
  },
  {
    hash: "process",
    label: "Process",
  },
  {
    hash: "solution",
    label: "Solution",
  },
  {
    hash: "key-metrics",
    label: "Key Metrics",
  },
  {
    hash: "future-iteration",
    label: "Future Iteration",
  },
];

const rogersNavItems: NavItem[] = [
  {
    hash: "overview",
    label: "Overview",
  },
  {
    hash: "working-process",
    label: "Working process",
  },
  {
    hash: "intake-tickets",
    label: "Intake tickets",
  },
  {
    hash: "ds-internal-meeting",
    label: "DS internal meeting",
  },
  {
    hash: "shape-up",
    label: "Shape up!",
  },
  {
    hash: "build-the-component",
    label: "Build the component",
  },
  {
    hash: "documentation-and-dev-hand-off",
    label: "Documentation and Dev hand-off",
  },
  {
    hash: "visual-qa-and-follow-up-questions",
    label: "Visual QA and follow-up questions",
  },
  {
    hash: "at-the-end-of-the-day",
    label: "At the end of the ‘day’",
  },
];

const singleNavItems: NavItem[] = [{ hash: "overview", label: "Overview" }];

export enum WorkPageType {
  bmo = "bmo",
  rogers = "rogers",
  snc = "snc",
  rbc = "rbc",
  withWomen = "with-women",
  iCity = "icity",
  bmoDs = "bmo-ds",
}

export const getNavItems = (type: WorkPageType): NavItem[] => {
  return (
    {
      [WorkPageType.bmo]: bmoNavItems,
      [WorkPageType.rogers]: rogersNavItems,
      [WorkPageType.snc]: singleNavItems,
      [WorkPageType.rbc]: singleNavItems,
      [WorkPageType.withWomen]: singleNavItems,
      [WorkPageType.iCity]: singleNavItems,
      [WorkPageType.bmoDs]: singleNavItems,
    }[type] || []
  );
};
