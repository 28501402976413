import { Box, Grid } from "@mui/material";
import { withPageWrapper } from "../../components/withPageWrapper";
import { WorkNav } from "./WorkNav/WorkNav";
import { PropsWithChildren, useMemo } from "react";
import { Breakpoints, useCurrentBreakpoint } from "../../services";
import { WorkPageType } from "./navParts";
import useScrollSpy, { ScrollSpyItem } from "../../services/useScrollSpy";

export const WORK_PAGE_RESPONSIVE_DATA = {
  [Breakpoints.maximum]: {
    containerPaddingY: "160px",
    containerPaddingX: "0px",
  },
  [Breakpoints.desktop]: {
    containerPaddingY: "160px",
    containerPaddingX: "0px",
  },
  [Breakpoints.tablet]: {
    containerPaddingY: "80px",
    containerPaddingX: "24px",
  },
  [Breakpoints.mobile]: {
    containerPaddingY: "40px",
    containerPaddingX: "16px",
  },
};

export interface NavItem extends ScrollSpyItem {
  label: string;
}

interface Props {
  workPageType?: WorkPageType;
  navItems?: NavItem[];
}

const Work: React.FC<PropsWithChildren<Props>> = ({
  children,
  navItems = [],
}) => {
  const { activePart } = useScrollSpy({ items: navItems });
  const currentBreakpoint = useCurrentBreakpoint();
  const responsiveUI = useMemo(
    () => WORK_PAGE_RESPONSIVE_DATA[currentBreakpoint],
    [currentBreakpoint]
  );
  const displayNav =
    currentBreakpoint === Breakpoints.maximum ||
    currentBreakpoint === Breakpoints.desktop;

  const hasMultiNav = navItems.length > 1;

  return (
    <Box
      sx={{
        width: "100%",
        paddingX: responsiveUI.containerPaddingX,
        maxWidth: "1440px",
        margin: "0 auto",
      }}
    >
      <Grid container columnSpacing={{ xs: 2, sm: 3 }}>
        <Grid item sm={0} md={hasMultiNav ? 1 : 2} />
        <Grid item sm={0} md={hasMultiNav ? 2 : 0}>
          {displayNav && hasMultiNav && (
            <WorkNav activeItem={activePart} navItems={navItems} />
          )}
        </Grid>
        <Grid item sm={12} md={8}>
          {children}
        </Grid>
        <Grid item sm={0} md={hasMultiNav ? 1 : 2} />
      </Grid>
    </Box>
  );
};
export const WorkPage = withPageWrapper(Work);
