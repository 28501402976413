import { useState, useEffect, useRef, useCallback } from "react";
import useThrottledOnScroll from "./useThrottledOnScroll";

export interface ScrollSpyItem extends Record<string, any> {
  hash: string;
}

const useScrollSpy = ({ items = [] }: { items: ScrollSpyItem[] }) => {
  const itemsWithNodeRef = useRef<ScrollSpyItem[]>([]);
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items);
  }, [items]);

  const [activePart, setActivePart] = useState<string | null>(null);

  const headerNode = document.getElementById("text-header");

  const findActiveIndex = useCallback(() => {
    let active;
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 200) {
        active = { hash: null };
        break;
      }

      const item = itemsWithNodeRef.current[i];

      if (!item.node) {
        console.error(
          `Missing node on the item ${JSON.stringify(item, null, 2)}`
        );
      }

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8 +
            (headerNode?.clientHeight ?? 0)
      ) {
        active = item;
        break;
      }
    }

    if (active && activePart !== active.hash) {
      setActivePart(active.hash);
    }
  }, [activePart, headerNode?.clientHeight]);

  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166);

  return {
    activePart,
  };
};

const getItemsClient = (items: { hash: string }[]) =>
  items.map(({ hash }) => ({ hash, node: document.getElementById(hash) }));

export default useScrollSpy;
