import { findIndex } from "lodash";
import { NavItem } from "../WorkPage";
import { NavWrapper, StyledTab, StyledTabs } from "./WorkNav.styled";

interface Props {
  activeItem: string | null;
  navItems: NavItem[];
}

export const WorkNav: React.FC<Props> = ({ activeItem, navItems }) => {
  const activeIndex = activeItem
    ? findIndex(navItems, ["hash", activeItem])
    : false;

  const tabsHTML = navItems.map(({ label, hash }) => (
    <StyledTab
      key={`tab-${hash}`}
      label={label}
      isActive={activeItem === hash}
      onClick={(e) => {
        e.preventDefault();
        const target = window.document.getElementById(hash);

        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
        }
      }}
    />
  ));

  return (
    <NavWrapper>
      <StyledTabs value={activeIndex}>{tabsHTML}</StyledTabs>
    </NavWrapper>
  );
};
