import React from "react";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { Box } from "@mui/material";
import { ScrollToTop } from "./ScrollToTop/ScrollToTop";

export const withPageWrapper =
  <P extends Object>(Component: React.FC<P>) =>
  (props: P) =>
    (
      <>
        <ScrollToTop />
        <Box sx={{ maxWidth: "1440px" }}>
          <Header />
          <Component {...props} />
          <Footer />
        </Box>
      </>
    );
