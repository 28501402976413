import { Box, styled } from "@mui/material";
import { StyledTypography } from "../../components/styles/styled-components";

export const Avatar = styled("img")({
  width: "100%",
  borderRadius: "100%",
});

export const SubLabel = styled(StyledTypography)({
  fontSize: "22px",
  fontWeight: 700,
});

export const StyledText = styled(StyledTypography)({
  fontSize: "18px",
  fontWeight: 500,
});

export const ItemsContainer = styled(Box)({
  marginTop: "24px",
});
