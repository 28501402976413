import { styled } from "@mui/material";
import Zoom from "react-medium-image-zoom";

interface Props {
  src: string;
  alt: string;
  $noMargin?: boolean;
}

const ImageWrapper = styled("img")<{ $noMargin?: boolean }>(
  ({ $noMargin }) => ({
    width: "100%",
    marginBottom: $noMargin ? undefined : "8px",
    display: $noMargin ? "block" : undefined,
  })
);

export const ImageContainer: React.FC<Props> = (props) => (
  <Zoom classDialog="custom-zoom">
    <ImageWrapper {...props} />
  </Zoom>
);
