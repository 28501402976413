import { Box, styled } from "@mui/material";
import { StyledTypography } from "../styles/styled-components";
import { colors } from "../../common";

export const HeaderWrapper = styled(Box)<{ paddingX: string }>(
  ({ paddingX }) => ({
    padding: `26px ${paddingX}`,
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "54px",
    margin: "0 auto",
  })
);

export const TitleWrapper = styled(StyledTypography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "54px",
  height: "54px",
  overflow: "hidden",
});

export const NavWrapper = styled(Box)({
  display: "flex",
  fontSize: "18px",
  fontWeight: 500,
});

export const NavItem = styled(StyledTypography)({
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "54px",
  color: colors.white,
});
