import { WorkPage } from "../../components/WorkPageTemplate/WorkPage";
import {
  WorkPageType,
  getNavItems,
} from "../../components/WorkPageTemplate/navParts";
import { WithWomenDetails } from "./WithWomenDetails";

export const WithWomenPage: React.FC = () => {
  const navItems = getNavItems(WorkPageType.withWomen);
  return (
    <WorkPage workPageType={WorkPageType.withWomen} navItems={navItems}>
      <WithWomenDetails navItems={navItems} />
    </WorkPage>
  );
};
